<template>
  <div class="error-page">
    <img src="@/assets/images/500.jpg" alt />
    <h1 class="content">{{ $t("errorPage.serverError.content") }}</h1>
    <p class="note">{{ $t("errorPage.serverError.note1") }}</p>
    <p class="note">{{ $t("errorPage.serverError.note2") }}</p>
    <p class="note">
      {{ $t("errorPage.serverError.hotline", { phone: hotline }) }}
    </p>
    <el-button type="text" class="mt-2" @click="goBackHome">{{
      $t("buttons.goBackHome")
    }}</el-button>
  </div>
</template>

<script>
import CONSTANTS from '@/config/constants'
export default {
  name: 'ServerError',
  data() {
    return {
      window,
      hotline: CONSTANTS.HOTLINE
    }
  },
  methods: {
    goBackHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss" scoped></style>
